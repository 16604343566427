(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

require("core-js/fn/object/assign.js");

require("core-js/fn/array/find.js");

require("core-js/fn/array/find-index.js");

require("core-js/fn/array/fill.js");

require("core-js/fn/string/starts-with.js");

require("core-js/fn/array/includes.js");

require("core-js/fn/array/from.js");

require("core-js/fn/promise/index.js");

require("core-js/fn/promise/finally.js");

require("whatwg-fetch");

var _smoothscrollPolyfill = require("smoothscroll-polyfill");

var _smoothscrollPolyfill2 = _interopRequireDefault(_smoothscrollPolyfill);

require("core-js/es6/map.js");

require("core-js/es6/set.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// window.fetch
var isSmoothScrollSupported = "scrollBehavior" in document.documentElement.style;

if (!isSmoothScrollSupported) {
  _smoothscrollPolyfill2.default.polyfill();
}

// for react 16
// https://facebook.github.io/react/blog/2017/09/26/react-v16.0.html#javascript-environment-requirements

},{"core-js/es6/map.js":"core-js/es6/map.js","core-js/es6/set.js":"core-js/es6/set.js","core-js/fn/array/fill.js":"core-js/fn/array/fill.js","core-js/fn/array/find-index.js":"core-js/fn/array/find-index.js","core-js/fn/array/find.js":"core-js/fn/array/find.js","core-js/fn/array/from.js":"core-js/fn/array/from.js","core-js/fn/array/includes.js":"core-js/fn/array/includes.js","core-js/fn/object/assign.js":"core-js/fn/object/assign.js","core-js/fn/promise/finally.js":"core-js/fn/promise/finally.js","core-js/fn/promise/index.js":"core-js/fn/promise/index.js","core-js/fn/string/starts-with.js":"core-js/fn/string/starts-with.js","smoothscroll-polyfill":"smoothscroll-polyfill","whatwg-fetch":"whatwg-fetch"}]},{},[1]);
